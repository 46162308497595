<template lang="pug">
    div(:class="'catalog-option-' + path")
      .catalog-option-header
        .title {{folder.title}}
        .icon-edit(v-if="hasItemsToSave && !localEditMode" @click="onEditModeClick")

      .catalog_edit_header(v-if="hasItemsToSave")
        .name
          | Наименовение
        .cost
          | Себестоимость за ед.
        .price
          | {{(folder.type && folder.type === "percent") ? "%" : "Цена за ед."}}
      .catalog_edit(v-for="(item, index) in costs")
          .name
            | {{item.title}}
          template(v-if="folder.type && folder.type === 'percent'")
            .cost
              .value(v-if="!localEditMode") {{item.cost| percent }}
              money(v-else v-model="item.cost" v-bind="percent")
            .price
              .value(v-if="!localEditMode") {{item.price| percent }}
              money(v-else v-model="item.price" v-bind="percent")

          template(v-else)
            .cost
              .value(v-if="!localEditMode") {{item.cost| cost0 }}
              money(v-else v-model="item.cost" v-bind="money")
            .price
              .value(v-if="!localEditMode") {{item.price| cost0 }}
              money(v-else v-model="item.price" v-bind="money")

      .catalog-save(v-if="localEditMode")
        .save(@click="save")
          |  Сохранить
        .cancel(@click="cancel")
          |  Отмена

      block-for-edit(v-for="(item, index) in options" :folder.sync="item" :path="localPath" :pathTitle="folder.title" @update="onUpdate")



</template>

<script>
import BlockForEdit from '@/components/Admin/Settings/BlockForEdit';
import {Money} from 'v-money'

export default {
  name: "BlockForEdit",
  props: ['folder', 'path', 'pathTitle'],
  components: {BlockForEdit, Money},
  data() {
      return {
        // options: null,
        localPath: 0,
        localPathTitle: '',
        localEditMode: false,
        hasItemsToSave: false,
        editData: null,
        money: {
          decimal: '.',
          thousands: ' ',
          prefix: '',
          suffix: ' ₽',
          precision: 0,
          masked: false
        },
        percent: {
          decimal: '.',
          thousands: ' ',
          prefix: '',
          suffix: ' %',
          precision: 0,
          masked: false
        }
      }
  },
  created() {
    this.hasItemsToSave = this.folder.options.filter(item => item.hasOwnProperty('cost')).length > 0
    this.localPath = this.path + 1
  },
  watch: {
    folder (val) {
      this.hasItemsToSave = val.options.filter(item => item.hasOwnProperty('cost')).length > 0
      this.localEditMode = false
    }
  },
  computed: {
    options () {
      return this.folder.options.filter(item => item.hasOwnProperty('options'))
    },
    costs () {
      return this.folder.options.filter(item => item.hasOwnProperty('cost'))
    }
  },
  methods: {
    save () {
      this.localEditMode = false
      this.$emit('update', this.options)
    },
    cancel () {
      this.oldData.forEach(old => {
        let el = this.folder.options.find(item => item.key === old.key)
        el.cost = old.cost
        el.price = old.price
      })
      this.localEditMode = false
    },
    onUpdate() {
      this.$emit('update', this.options)
    },
    onEditModeClick () {
      this.oldData = JSON.parse(JSON.stringify(this.costs));
      this.localEditMode = true
    }
  }
}
</script>

<style scoped>

</style>
