<template lang="pug">
  .admin
    .order(v-if="order")
      .back
        kraus-button(:label="'Назад к списку заявок'",
          :icon="require('@/assets/images/ui/icon-arrow-back.svg')"
          :border="false",
          @click="onBackClick")
      .header
        .title
          .h1
            |  Заявка -  {{order.pid}}
        .left
          .left-item
            kraus-button(:label="'Скачать PDF'",
              :icon="require('@/assets/images/ui/icon-download.svg')"
              :border="true",
              @click="onPdfClick")
          .left-item
            kraus-select(v-if='status' :values="statuses", @input="onStatusChange" v-model="status", :placeholder="'Статус'", :css="status.value === 1 ? 'red' : 'blue'")
      .content
        .right
          .image-block(v-for="(image, index) in order.stagesImages" :key="index")
              img(:src="image.data")
          .info-block
            .row
              .info-block-title
                | {{order.equipment.containerType.key === 'obreshotka' ? "Модульное здание из обрешеточных контейнеров" : "Модульное здание из сэндвич-панелей"}}
              .order-cost-big
                | {{order.costs.totalCost| cost}}
            .row
              .project-item
                .value
                  | {{stagesCount}}
                .text-gray
                  | Этажи
              .project-item
                .value
                  | {{boxesCount}}
                .text-gray
                  | Блоки
              .project-item
                .value
                  | {{totalSize}}
                .text-gray
                  | Размер
              .project-item
                .value
                  | {{order.info.square}} м²
                .text-gray
                  | Общая площадь
              .project-item
                .value
                  | 7-14 дней
                .text-gray
                  | Срок изготовления


          .equipment-block
            .row
              .equipment-block-title
                | Каркас
              .order-cost-title
                | {{order.costs.carcassCost.total| currency}}
            .row(v-if="order.equipment.cornerPosts")
              .col1
                | Угловые стойки
              .col2
                | {{order.equipment.cornerPosts.label}}
              .col3
                | {{order.costs.carcassCost.cornerPosts| currency}}
            .row
              .col1
                |  Цвет каркаса
              .col2
                | {{order.equipment.carcassColor.title}}
              .col3
                | {{order.costs.carcassCost.color| currency}}
            .row
              .col1
                |  Усиление каркаса
              .col2
                | {{order.equipment.carcassStrengthening ? 'Да' : 'Нет'}}
              .col3
                | {{order.costs.carcassCost.strengthening| currency}}






            .divider
            .row
              .equipment-block-title
                | Кровля
              .order-cost-title
                | {{order.costs.roofCost.total| currency}}
            .row
              .col1
                | Тип кровли
              .col2
                | {{order.equipment.roofType.title}}
              .col3
                |
            .row
              .col1
                |  Цвет
              .col2
                | {{order.equipment.roofColor.title}}
              .col3
                | {{order.costs.roofCost.color| currency}}
            .row
              .col1
                |  Материал
              .col2
                | {{order.equipment.roofMaterial.title}}
              .col3
                | {{order.costs.roofCost.material| currency}}
            .row
              .col1
                |   Металлические фермы
              .col2
                | {{order.equipment.roofMetalTrusses ? 'Да' : 'Нет'}}
              .col3
                | {{order.costs.roofCost.metalTrusses| currency}}







            .divider

            .row
              .equipment-block-title
                | Внешняя отделка
              .order-cost-title
                | {{order.costs.externalFinishingCost.total| currency}}
            .row
              .col1
                | Стены
              .col2
                | {{order.equipment.wallMaterial.title}}
              .col3
                | {{order.costs.externalFinishingCost.material.price| currency}}
            .row
              .col1
                |  Покрытие
              .col2
                | {{order.equipment.wallColor.title}}
              .col3
                | {{order.costs.externalFinishingCost.color.price| currency}}




            .divider
            .row
              .equipment-block-title
                | Внутренняя отделка стен
            .row(v-if="order.equipment.containerType.key !== 'obreshotka'")
              .col1
                | Стены
              .col2
                | {{order.equipment.wallMaterial.title}}
              .col3
                | {{order.costs.externalFinishingCost.material.price| currency}}

            .row(v-for="(item, index2) in internalFinishing")
              .col1
                | {{order.equipment.containerType.key === 'obreshotka' ? "Контейнеры" : "Цвет"}} - {{item.count}} шт.
              .col2
                |{{item.dictionaryData.wallMaterial.title}}
              .col3
                | {{item.price| currency}}





            .divider

            .row
              .equipment-block-title
                | Пол
              .order-cost-title
                | {{order.costs.floorCost.total| currency}}
            .row.title
              .col1
                | Черновой пол
              .col2
                |
              .col3
                |
            .row(v-if="order.equipment.additionalFloor")
              .col1
                | Дополнительное дно
              .col2
                | {{order.equipment.additionalFloor.title}}
              .col3
                | {{order.costs.floorCost.additionalFloor| currency}}
            .row
              .col1
                | Дополнительные лаги
              .col2
                | {{order.equipment.additionalLags ? 'Да' : 'Нет'}}
              .col3
                | {{order.costs.floorCost.additionalLags| currency}}
            .row
              .col1
                | Металлические лаги
              .col2
                | {{order.equipment.metalLags ? 'Да' : 'Нет'}}
              .col3
                | {{order.costs.floorCost.metalLags| currency}}


            .row.title
              .col1
                | Чистовой пол
              .col2
                |
              .col3
                |
            .row
              .col1
                | Основной пол
              .col2
                | {{order.equipment.mainFloor.title}}
              .col3
                | {{order.costs.floorCost.mainFloor| currency}}
            .row
              .col1
                | Дополнительный слой
              .col2
                | {{order.equipment.additionalMainFloor.title}}
              .col3
                | {{order.costs.floorCost.additionalMainFloor| currency}}
            .row.title
              .col1
                | Покрытие
              .col2
                |
              .col3
                |
            .row(v-for="(item, index2) in floorCoating")
              .col1
                |  Контейнеры - {{item.count}} шт.
              .col2
                | {{item.coating.title}}
              .col3
                | {{item.price| currency}}


            .divider
            .row
              .equipment-block-title
                |  Перегородки
              .order-cost-title
                | {{order.costs.insideWallsCost.total| currency}}

            .row(v-for="(item, index2) in wallsInside")
              .col1
                | {{item.material.title}}
              .col2
                |
              .col3
                .count {{item.count}} шт.
                | {{item.price| currency}}



            .divider

            .row
              .equipment-block-title
                | Двери
              .order-cost-title
                | {{order.costs.doorsCost.total| currency}}

            .row.title
              .col1
                | Межкомнатные
              .col2
                |
              .col3
                |

            .row(v-for="(item, index2) in doorsInside")
              .col1
                | {{item.dictionaryData.title}}
              .col2
                | {{doorProps(item.dictionaryData)}}
              .col3
                .count {{item.count}} шт.
                | {{item.price| currency}}

            .row.title
              .col1
                | Внешние
              .col2
                |
              .col3
                |

            .row(v-for="(item, index2) in doorsOutside")
              .col1
                | {{item.dictionaryData.title}}
              .col2
                | {{doorProps(item.dictionaryData)}}
              .col3
                .count {{item.count}} шт.
                | {{item.price| currency}}



            .divider
            .row
              .equipment-block-title
                |  Окна
              .order-cost-title
                | {{order.costs.windowsCost.total| currency}}

            .row(v-for="(item, index2) in orderWindows")
              .col1
                | {{item.dictionaryData.title}}
              .col2
                | {{windowProps(item.dictionaryData)}}
              .col3
                .count {{item.count}} шт.
                | {{item.price| currency}}




            .divider
            .row
              .equipment-block-title
                |  Сантехника
              .order-cost-title
                | {{order.costs.plumbingCost.total| currency}}

            .row(v-for="(item, index2) in plumbing")
              .col1
                | {{item.dictionaryData.title}}
              .col3
                .count {{item.count}} шт.
                | {{item.price| currency}}


            .divider
            .row
              .equipment-block-title
                | Утепление
              .order-cost-title
                | {{order.costs.warmingCost.total| currency}}
            .row
              .col1
                | Стены, Пол, Потолок
              .col2
                | {{order.equipment.warming.type.title}} {{order.equipment.warming.thickness.title}}
              .col3
                | {{order.costs.warmingCost.total| currency}}


            .divider
            .row
              .equipment-block-title
                | Гидроизоляция
              .order-cost-title
                | {{order.costs.waterProofCost.total| currency}}
            .row(v-if="order.equipment.containerType.key === 'obreshotka'")
              .col1
                | Пленка ПВХ 40мкр
              .col2
                | Да
              .col3
                |
            .row
              .col1
                |  Гидропроф внешний, А
              .col2
                | {{order.equipment.waterproofing.external || order.equipment.containerType.key !== 'obreshotka' ? 'Да' : 'Нет'}}
              .col3
                | {{order.costs.waterProofCost.external| currency}}
            .row
              .col1
                  | Гидропроф внутренний, Б
              .col2
                | {{order.equipment.waterproofing.internal ? 'Да' : 'Нет'}}
              .col3
                | {{order.costs.waterProofCost.internal| currency}}

            .divider
            .row
              .equipment-block-title
                | Электрика
              .order-cost-title
                | {{order.costs.electricCost.total| currency}}
            .row
              .col1
                | {{order.equipment.electricPacket.title}}
              .col3
                | {{order.costs.electricCost.packet| currency}}

            .row.title
              .col1
                | Розетки
              .col2
                |
              .col3
                |
            .row(v-for="(item, index2) in order.equipment.electricSockets")
              .col1
                | {{item.title}}
              .col3
                .count {{item.count}} шт.

            .row.title
              .col1
                | Освещение
              .col2
                |
              .col3
                |
            .row(v-for="(item, index2) in order.equipment.electricLights")
              .col1
                | {{item.title}}
              .col3
                .count {{item.count}} шт.

            .divider
            .row
              .equipment-block-title
                |  Дополнительные элементы
              .order-cost-title
                | {{order.costs.additionalCost.total| currency}}

            .row(v-for="(item, index2) in additional")
              .col1
                | {{item.dictionaryData.title}}
              .col3
                .count {{item.count}} шт.
                | {{item.price| currency}}




            .divider

          .info-block
            .info-block-title
              |  Комментарий клиента
            .text-comment
              | {{order.projectComment}}

          .info-block(style="background-color:#FAFBFB;")
            .info-block-title
              div Комментарий менеджера
              .icon-edit(v-if="!commentEditMode" @click="onEditModeClick")
            .comment-form(v-if="commentEditMode")
              textarea-autosize(v-model="order.managerComment",
                ref='textarea',
                placeholder="Здесь вы можете оставить дополнительные пожелания и комментарии",
                :min-height="56"
              )
            .row(v-else)
              .row-item
                .text-gray
                  | {{order.managerComment}}
            .row
              .row-item
                .text-blue
                  | Доп. cтоимость
                money(v-model="order.dopCost" v-bind="money" v-if="commentEditMode")
                .text-cost(v-else)
                  | {{order.dopCost| cost0}}
              .row-item
                .text-blue
                  | Доп. сроки
                input(placeholder="0 дней" v-model="order.dopDays"  v-if="commentEditMode")
                .text-gray(v-else)
                  | {{order.dopDays}}
            .row(v-if="commentEditMode")
              kraus-button(:label="'Сохранить'",
                :colors="{label: '#FFFFFF', background: '#0558AB'}",
                :border="true",
                @click="onSaveClick")









        .left
          .info-block
            .text-gray
              | Полная стоимость заявки
            .order-cost-big
              | {{totalCost| cost}}

            .text-gray
              | Дата поступления заявки
            .text-blue
              | {{showDate(order.created_at)}}

          .info-block
            .info-block-title
              | Заказчик
            .text-blue
              | {{order.customerContacts.organization}}
            .text-gray
              | ИНН {{order.customerContacts.inn}}
            .divider
            .phone
              .icon
              .value
                | {{order.customerContacts.phone}}
            .email
              .icon
              .value
                | {{order.customerContacts.email}}

          .info-block
            .info-block-title
              | Доставка
            .address
              .icon
              .value
                | {{order.customerContacts.address}}
            .row
              .date
                .icon
                .value
                  | {{order.customerContacts.date}}
              //.time
              //  .icon
              //  .value
              //    | {{order.customerContacts.time}}
            .divider
            .row
              .text-gray
                | Разгрузка краном заказчика
              .text-blue
                | {{order.customerCraneUnloading ? "Да" : "Нет"}}
            .row
              .text-gray
                | Расстояние
              .text-blue
                | {{order.costs.delivery.distance}} км
            .row
              .text-gray
                | Стоимость
              .text-cost
                | {{order.costs.delivery.cost| currency}}

            .divider
            .text-gray
              | Контактное лицо
            .text-bluetotalCost
              | {{order.customerContacts.contactName}}

          .info-block
            .info-block-title
              | Монтаж
            .text-blue
              | {{order.equipment.mounting.title}}
            .text-cost
              | {{order.costs.mountingCost.total| currency}}

      .divider
      .footer
        .right
          .text
            .blue
              | Полная стоимость заявки
            .gray
              | с учетом комментария менеджера
          .order-cost
            |  {{totalCost| cost}}
        .left
          .left-item
            kraus-button(:label="'Скачать PDF'",
              :icon="require('@/assets/images/ui/icon-download.svg')"
              :border="true",
              @click="onPdfClick")
          .left-item
            kraus-select(v-if='status' :values="statuses", v-model="status", :placeholder="'Статус'", :css="status.value === 1 ? 'red' : 'blue'")

</template>

<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import KrausSelect from '@/components/_common/ui/KrausSelect';
import api from '@/common/http/api';
import moment from 'moment'
import {Money} from 'v-money'

export default {
  name: "Order",
  components: { KrausButton, KrausSelect, Money },
  data() {
      return {
        status: null,
        statuses: [{title:'Ожидает внимания', value:1}, {title:'Выполняется', value:2}],
        dopCost: '',
        dopDays: '',
        comment: '',
        order: null,
        orderId: null,
        commentEditMode: false,
        money: {
          decimal: '.',
          thousands: ' ',
          prefix: '',
          suffix: ' ₽',
          precision: 0,
          masked: false
        }

      }
  },
  created() {
    this.orderId = this.$route.params.id
    this.getOrder()
  },
  computed: {
    totalCost() {
      let res = this.order.costs.totalCost
      res = res + parseInt(this.order.dopCost ? this.order.dopCost : 0)
      res = res + parseInt((this.order.costs.mountingCost &&  this.order.costs.mountingCost.total) ? this.order.costs.mountingCost.total : 0)
      res = res + parseInt(this.order.costs.delivery.cost ? this.order.costs.delivery.cost : 0)

      return res;
    },
    totalSize() {
      let res = '-';

      if (this.order.costs.containersCost.size) {
        let tmp = this.order.costs.containersCost.size
        res =  tmp.width + 'м х ' + tmp.height+ 'м х ' + tmp.depth + 'м'
      }

      return res;
    },
    stagesCount () {
      return this.order.stagesImages.length
    },
    boxesCount () {
      let boxes = 0
      for (let floor in this.order.fullJson) {
        if (this.order.fullJson[floor]) {
          boxes = boxes + this.order.fullJson[floor].boxes.length
        }
      }
      return boxes
    },
    orderWindows () {
      let res = []

      this.order.costs.windowsCost.items.forEach(door => {
        let key = door.dictionaryData.id + '-' + this.windowProps(door.dictionaryData)
        let t = res.find(item => {
          return item.uid === key
        })

        if (t) {
          t.count = t.count + 1
          t.price = t.price + door.price
        } else {
          let val = door
          val.uid = key
          val.count = 1
          res.push(val)
        }
      })

      return res
    },

    wallsInside () {
      let res = []

      this.order.costs.insideWallsCost.items.forEach((wallItem) => {

        let key = wallItem.material.key
        let t = res.find(item => {
          return item.uid === key
        })

        if (t) {
          t.count = t.count + 1
          t.price = t.price + wallItem.price
        } else {
          let val = wallItem
          val.uid = key
          val.count = 1
          res.push(val)
        }
      })

      return res
    },

    floorCoating () {
      let res = []

      this.order.costs.floorCost.items.forEach((wallItem) => {

        let key = wallItem.coating.key
        let t = res.find(item => {
          return item.uid === key
        })

        if (t) {
          t.count = t.count + 1
          t.price = t.price + wallItem.price
        } else {
          let val = wallItem
          val.uid = key
          val.count = 1
          res.push(val)
        }
      })

      return res
    },

    internalFinishing () {
      let res = []
      this.order.costs.internalFinishingCost.items.forEach((wallItem) => {

        let key = wallItem.wallMaterial.key
        let t = res.find(item => {
          return item.uid === key
        })

        if (t) {
          t.count = t.count + 1
          t.price = t.price + wallItem.price
        } else {
          let val = wallItem
          val.uid = key
          val.count = 1
          res.push(val)
        }
      })

      return res
    },

    plumbing () {
      let res = []
      this.order.costs.plumbingCost.items.forEach(door => {
        let key = door.dictionaryData.id
        if (key === 'boyler') {
          door.dictionaryData.title = door.dictionaryData.option.title
          key = door.dictionaryData.option.key
        }
        let t = res.find(item => {
          return item.uid === key
        })

        if (t) {
          t.count = t.count + 1
          t.price = t.price + door.price
        } else {
          let val = door
          val.uid = key
          val.count = 1
          res.push(val)
        }
      })
      return res
    },

    additional () {
      let res = []
      this.order.costs.additionalCost.items.forEach(door => {
        let key = door.dictionaryData.id
        let t = res.find(item => {
          return item.uid === key
        })

        if (t) {
          t.count = t.count + 1
          t.price = t.price + door.price
        } else {
          let val = door
          val.uid = key
          val.count = 1
          res.push(val)
        }
      })
      return res
    },

    doorsInside () {
      return this.doors(true)
    },
    doorsOutside () {
      return this.doors(false)
    }
  },

  methods: {
    doors (isInside) {
      let res = []

      this.order.costs.doorsCost.items.forEach(door => {
        if (door.dictionaryData.inside === isInside) {
          let key = door.dictionaryData.id + this.doorProps(door.dictionaryData)
          let t = res.find(item => {
            return item.uid === key
          })

          if (t) {
            t.count = t.count + 1
            t.price = t.price + door.price
          } else {
            let val = door
            val.uid = key
            val.count = 1
            res.push(val)
          }
        }
      })

      return res
    },
    onEditModeClick () {
      this.commentEditMode = true
    },
    onStatusChange () {
      api.post('/order/status',
          {
            id: this.order._id,
            status: this.status.value,
          })
          .then((response) => {
          })

    },
    viewedSet () {
      api.post('/order/viewed',
          {
            id: this.order._id,
          })
          .then((response) => {
          })

    },
    showDate(created_at) {
      return moment(created_at).format("DD.MM.YYYY HH:mm")
    },
    getOrder () {
      api.get('/order/' + this.orderId)
      .then((response) => {
        this.order = response.data
        this.status = this.statuses.find(item => { return item.value === this.order.status})
        if (!this.order.viewed) {
          this.viewedSet()
        }
      })
    },

    doorProps (item) {
      let res = ''
      if (item.size) {
        res = res + (item.size.title ? item.size.title : item.size.width + ' x ' + item.size.height)
      }
      res = res + (item.color ? ', ' + item.color.title : '')
      res = res + (item.boltEnabled ? ', засов'  : '')
      res = res + (item.lock1Enabled ? ', 1й замок'  : '')
      res = res + (item.lock2Enabled ? ', 2й замок'  : '')
      res = res + (item.metalFinishingEnabled ? ', замена дермантина металлом'  : '')

      return res
    },

    windowProps (item) {
      let res = item.section
      res = res + (item.latticeEnabled ? ', решетка на окно'  : '')
      res = res + (item.rollerShuttersEnabled ? ', рольставни'  : '')

      return res
    },

    onBackClick () {
      this.$router.go(-1)
    },
    onPdfClick () {
      // window.open(api.defaults.baseURL + 'order/pdf/' + this.order._id)
      location.href = api.defaults.baseURL + 'order/pdf/' + this.order._id;
    },
    onSaveClick () {
      this.commentEditMode = false
      api.post('/order/dopData',
          {
            id: this.order._id,
            managerComment: this.order.managerComment,
            dopDays: this.order.dopDays,
            dopCost: this.order.dopCost
          })
          .then((response) => {
          })
    }
  }
}
</script>

<style scoped>

</style>
