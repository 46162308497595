<template lang="pug">
  .admin
    .h1
      |  Заявки
    .orders
      .header
        .filters
          .search
            input(placeholder="Поиск" v-model="searchString" @keyup="debounceFilter")
          .date
            date-picker(placeholder='Дата' v-model="searchDate" valueType="format" @change="debounceFilter")
            .date_clear(v-if="searchDate" @click="searchDate=null; debounceFilter()")
              | x
          kraus-select(:values="statuses", v-model="status", :placeholder="'Статус'" @input="getOrdersList")
        .left
          kraus-button(:label="'Скачать XLS'",
            :icon="require('@/assets/images/ui/icon-download.svg')"
            :border="true",
            @click="onXlsClick")
      .table
        .table-header
          .td-id
            |№
          .td-date
            | ДАТА
          .td-customer
            | ЗАКАЗЧИК
          .td-order
            | ЗАЯВКА
          .td-order
            |  ПОЛНАЯ СТОИМОСТЬ
          .td-status
            | СТАТУС
        .table-row(v-for="(item, index) in orders" :key="index" @click="onOrderClick(item)" :class="{'gray': !item.viewed}")
          .td-id
            | {{item.pid}}
          .td-date
            | {{showDate(item.created_at)}}
          .td-customer
            .info
              .name
                | {{item.customerContacts.organization}}
              .contacts
                .phone
                  .icon
                  .value
                    | {{item.customerContacts.phone}}
                .email
                  .icon
                  .value
                    | {{item.customerContacts.email}}
          .td-order
            .info
              .container
                | {{item.containerType}}
              .cost
                | {{item.totalCost| cost}}
          .td-order
            .info
              .cost(style="padding-top: 24px")
                | {{item.managerCost| cost}}
          .td-status
            .value(:class="{waiting:item.status == 1, inprogress:item.status == 2}")
              .span(v-if="item.status == 1") Ожидает внимания
              .span(v-if="item.status == 2") Выполняется
      .pager(v-if="totalPages>0")
        .info
          | {{page}} из {{totalPages}}
        .arrow
          kraus-button(:label="''",
            :disabled="page === 1"
            :icon="require('@/assets/images/ui/icon-page-left.svg')"
            :border="true",
            @click="onPrevPage")

        .arrow
          kraus-button(:label="''",
            :disabled="page === totalPages"
            :icon="require('@/assets/images/ui/icon-page-right.svg')"
            :border="true",
            @click="onNextPage")


</template>

<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import KrausSelect from '@/components/_common/ui/KrausSelect';
import api from '@/common/http/api';
import moment from 'moment'
import _ from 'lodash'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: "Orders",
  components: { KrausButton, KrausSelect, DatePicker},
  data() {
      return {
        page: 1,
        onPage: 10,
        totalPages: 1,
        searchString: '',
        searchDate: null,
        status: {title:'Все заявки',value:0},
        statuses: [{title:'Все заявки',value:0}, {title:'Ожидает внимания',value:1}, {title:'Выполняется',value:2}],
        orders: []
      }
  },
  created() {
    // this.getOrdersList()
  },
  computed: {
  },
  methods: {
    showDate(created_at) {
      return moment(created_at).format("DD.MM.YYYY HH:mm")
    },
    debounceFilter: _.debounce(function (e) {
      this.getOrdersList();
    }, 400),

    getOrdersList () {
      let params = {
        offset: (this.page-1) * this.onPage,
        limit: this.onPage,
        status: this.status.value,
        search: this.searchString,
        date: this.searchDate
      }

      api.get('/order/list', {params: params})
      .then((response) => {
        this.orders = response.data.rows
        this.totalPages = Math.ceil(response.data.totalRows / this.onPage)
      })
    },
    onOrderClick (item) {
      this.$router.push({name: 'Order', params: { id: item._id } }, )
    },
    onXlsClick () {

    },
    onNextPage () {
      this.page =  this.page < this.totalPages ? this.page + 1 : this.page;
      this.getOrdersList()
    },
    onPrevPage () {
      this.page =  this.page > 1 ? this.page - 1 : 1;
      this.getOrdersList()
    }
  }
}
</script>

<style scoped>

</style>
