import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '@/components/Admin.vue';
import Login from '@/components/Login.vue';
import Settings from '@/components/Admin/Settings.vue';
import Orders from '@/components/Admin/Orders.vue';
import Order from '@/components/Admin/Order.vue';
import {store} from '@/store/index'
import api from '@/common/http/api';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Admin,
    redirect: '/orders',
    children: [
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
      },
      {
        path: '/orders',
        name: 'Orders',
        component: Orders,
      },
      {
        path: '/order/:id',
        name: 'Order',
        component: Order,
      }
    ]
  }, {
    path: '/login',
    name: 'Login',
    component: Login
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedUser = store.getters.user

  // next()
  // return;
  if (authRequired && loggedUser === null) {
      return next({
        path: '/login'
      })

      // api.post('/check', {
      //   email: 'admin@kraus.ru',
      //   password: '123234'
      // })
      // .then((response) => {
      //   let user = response.data
      //   if (user !== null) {
      //     store.dispatch('setUser', user)
      //     next();
      //     return;
      //   }
      //
      //   return next({
      //     path: '/login'
      //   })
      //
      // })
  } else {
    next()
  }
})

export default router;
