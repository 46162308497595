<template lang="pug">
  .kraus-input(:class="{focused: focused, 'has-error': inputData.error}")
    .icon(v-if="icon", :style="{backgroundImage: 'url(' + icon + ')'}")
    input(type="text",
      ref="input"
      v-model="inputModel",
      v-mask="mask",
      @focus="onFocus",
      @blur="onBlur",
      @keyup="onKeyUp",
      :style="{marginLeft: (icon ? 0 : 18) + 'px'}" v-if="!calendar && mask")
    input(:type="inputType",
      ref="input"
      v-model="inputModel",
      @focus="onFocus",
      @blur="onBlur",
      @keyup="onKeyUp",
      :style="{marginLeft: (icon ? 0 : 18) + 'px'}" v-else-if="!calendar && !mask")

    date-picker(v-else-if="calendar === 'date'",
      input-class=" ",
      format="DD.MM.YYYY",
      v-model="inputModel",
      :open="calendarOpened",
      @focus="calendarOpened = true"
    )
      template(v-slot:icon-calendar)
        span
      template(v-slot:icon-clear)
        span
    date-picker(v-else-if="calendar === 'time'",
      input-class=" ",
      type="time"
      format="HH:mm",
      v-model="inputModel",
      :open="calendarOpened",
      @focus="calendarOpened = true"
    )
      template(v-slot:icon-calendar)
        span
      template(v-slot:icon-clear)
        span

    .placeholder.no-select(
      :class="{small: focused || inputModel}",
      :style="{marginLeft: (icon ? 32 : 0) + 'px'}",
      @click="onPlaceHolderClick") {{placeholder}}

    .error(v-if="inputData.error") {{inputData.error}}
</template>
<script>
import {mask} from 'vue-the-mask'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'KrausInput',
  directives: { mask },
  components: { DatePicker },
  props: [
    'inputData',
    'icon',
    'placeholder',
    'mask',
    'validator',
    'calendar',
    'type'
  ],
  data() {
    return {
      inputModel: '',
      inputType: 'text',
      focused: false,
      calendarOpened: false,
    }
  },

  created() {
    this.inputModel = this.inputData.value.trim()
    this.inputType = this.type ? this.type : 'text';
    if (this.calendar) {
      document.addEventListener('mousedown', this.onDocumentMouseDown)
    }
  },

  destroyed() {
    document.addEventListener('mousedown', this.onDocumentMouseDown)
  },

  watch: {
    inputModel(val) {
      if (typeof val === 'string') {
        this.inputData.value = val ? val.trim() : ''
      }
      this.calendarOpened = false
    }
  },

  methods: {
    onDocumentMouseDown(e) {
      if (e.target.closest('.mx-datepicker-popup') === null) this.calendarOpened = false
    },

    onFocus() {
      this.focused = true
    },

    onBlur() {
      this.focused = false
      this.calendarOpened = false

      this.checkOnError()
    },

    onKeyUp() {
      // this.checkOnError()
    },

    checkOnError() {
      if (this.inputData.value.trim() === '') {
        this.inputData.error = 'Поле необходимо заполнить'
      } else {
        if (this.validator) {
          if (!this.validator.test(this.inputData.value)) {
            this.inputData.error = 'Поле заполнено неправильно'
          } else {
            this.inputData.error = ''
          }
        } else {
          this.inputData.error = ''
        }
      }
    },

    onPlaceHolderClick() {
      if (this.calendar) {
        this.calendarOpened = true
        console.log(this.calendarOpened)
      } else {
        if (this.$refs.input) this.$refs.input.focus()
      }
    }
  }
}
</script>
