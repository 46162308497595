<template lang="pug">
  #content
    .login-form
      .logo
        .icon-logo
      .email
        kraus-input(:inputData="email" :placeholder="'Email'")
      .password
        kraus-input(:inputData="password" :placeholder="'Пароль'" :type="'password'")
      .submit
        kraus-button(:label="'Войти'",
          :colors="{label: '#FFFFFF', background: '#0558AB'}",
          :border="true",
          @click="getAuth")
      .error
        | {{error}}
</template>

<script>

import KrausButton from '@/components/_common/ui/KrausButton';
import KrausInput from '@/components/_common/ui/KrausInput';
import api from '@/common/http/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Login",
  components: {KrausButton, KrausInput},
  data() {
    return {
      email: { value: '', error: '' },
      password:  { value: '', error: '' },
      error: ''
    }
  },
  created() {
    if (this.user) {
      this.$router.push({name: 'Index'})
    }
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
  },
  methods: {
    ...mapActions([
      'setUser',
    ]),
    getAuth () {
      this.error = ''
      api.post('/check', {
        email: this.email.value,
        password: this.password.value
      })
          .then((response) => {
            let user = response.data
            if (user.id !== null) {

              this.setUser(user)
              this.$router.push({name: 'Orders'})

            } else {
              this.setUser(null)
              this.error = 'Неправилный email или пароль'
            }
          })
    },

    onXlsClick () {

    }
  }

}
</script>

<style scoped>

</style>
