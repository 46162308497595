<template lang="pug">
  #app
    template(v-if="applicationReady")
      router-view
</template>
<script>
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { },
  data() {
    return {
      applicationReady: false,
    }
  },

  mounted() {
    EquipmentCategories.loadData().then(() => {
      this.applicationReady = true
    })
  },

  watch: {
  },

  computed: {
    ...mapGetters([
    ]),
  },

  methods: {
    ...mapActions([
        'setUser'
    ]),

  }
}
</script>
