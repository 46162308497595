<template lang="pug">
  .kraus-button.no-select(@click="onClick", :class="{disabled: disabled}", :style="buttonStyle")
    .icon(v-if="icon", :style="iconStyle")
    .label(v-if="label", :style="labelStyle") {{label}}
</template>
<script>
export default {
  name: 'KrausButton',
  props: {
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Object,
      default() {
        return {
          label: '#889EBF',
          background: '#ffffff'
        }
      }
    }
  },
  data() {
    return {}
  },

  computed: {
    iconStyle() {
      return {
        backgroundImage: 'url(' + this.icon + ')',
        marginRight: this.label ? '10px' : null
      }
    },
    labelStyle() {
      return {
        color: this.colors.label,
      }
    },
    buttonStyle() {
      return {
        borderWidth: this.border ? '1px' : '0',
        backgroundColor: this.colors.background
      }
    }
  },

  methods: {
    onClick() {
      if (!this.disabled) this.$emit('click')
    }
  }
}
</script>
