import Vue from 'vue';

Vue.filter('cost', function(val) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: 0
  })

  return parseInt(val) === 0 ? '' : formatter.format(val).replaceAll(',', ' ') + ' ₽'
})

Vue.filter('cost0', function(val) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: 0
  })

  return formatter.format(val).replaceAll(',', ' ') + ' ₽'
})

Vue.filter('percent', function(val) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: 0
  })

  return formatter.format(val).replaceAll(',', ' ') + ' %'
})


Vue.filter('currency', function(val) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: 0
  })

  return parseInt(val) === 0 ? '' : '+' + formatter.format(val).replaceAll(',', ' ') + ' ₽'
})

