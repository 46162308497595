<template lang="pug">
    div(:class="'catalog-option-' + path")
      .catalog-option-header
        .title {{folder.title}}
        .icon-edit(v-if="hasItemsToSave && !localEditMode" @click="onEditModeClick")

      .catalog_edit_header(v-if="hasItemsToSave")
        .name
          | Наименовение
        .hint
          | Текст подсказки
      .catalog_edit(v-for="(item, index) in hints")
          .name.hints
            | {{item.title}}
          .hint
            .value(v-if="!localEditMode") {{item.hint }}
            input(v-else v-model="item.hint")

      .catalog-save(v-if="localEditMode")
        .save(@click="save")
          |  Сохранить
        .cancel(@click="cancel")
          |  Отмена

      hint-for-edit(v-for="(item, index) in options" :folder.sync="item" :path="localPath" :pathTitle="folder.title" @update="onUpdate")



</template>

<script>
import HintForEdit from '@/components/Admin/Settings/HintForEdit';

export default {
  name: "HintForEdit",
  props: ['folder', 'path', 'pathTitle'],
  components: {HintForEdit},
  data() {
      return {
        // options: null,
        localPath: 0,
        localPathTitle: '',
        localEditMode: false,
        hasItemsToSave: false,
        editData: null
      }
  },
  created() {
    this.hasItemsToSave = this.folder.options.filter(item => item.hasOwnProperty('hint')).length > 0
    this.localPath = this.path + 1
  },
  watch: {
    folder (val) {
      this.hasItemsToSave = val.options.filter(item => item.hasOwnProperty('hint')).length > 0
      this.localEditMode = false
    }
  },
  computed: {
    options () {
      return this.folder.options.filter(item => item.hasOwnProperty('options'))
    },
    hints () {
      return this.folder.options.filter(item => item.hasOwnProperty('hint'))
    }
  },
  methods: {
    save () {
      this.localEditMode = false
      this.$emit('update', this.options)
    },
    cancel () {
      this.oldData.forEach(old => {
        let el = this.folder.options.find(item => item.key === old.key)
        el.hint = old.hint
      })
      this.localEditMode = false
    },
    onUpdate() {
      this.$emit('update', this.options)
    },
    onEditModeClick () {
      this.oldData = JSON.parse(JSON.stringify(this.hints));
      this.localEditMode = true
    }
  }
}
</script>

<style scoped>

</style>
