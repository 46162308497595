import api from '@/common/http/api';

class EquipmentCategories {
  static Instance = null

  _rawData
  _selectedContainer

  constructor(props) {
    if (EquipmentCategories.Instance !== null) {
      throw new Error('Already instantiated')
    }
  }

  static getInstance() {
    if (this.Instance === null) this.Instance = new EquipmentCategories()
    return this.Instance
  }

  async loadData() {
    const response = await api.get('/category/full')
    this._rawData = response.data
    return this._rawData
  }
  getAll() {
    return this._rawData
  }
  getItems({categoryKey, optionKey, containerType}) {
    const category = this.getCategory(categoryKey, containerType)
    let option = []

    if (category && category.options) {
      const filtered = category.options.filter(optionItem => optionItem.key === optionKey)
      option = filtered.length > 0 ? filtered[0] : []
    }

    return option
  }

  getCategory(categoryKey, containerType) {
    const selectedContainer = this._selectedContainer ? this._selectedContainer : containerType
    const filteredCategory = this._rawData[selectedContainer].filter(categoryItem => categoryItem.key === categoryKey)
    return filteredCategory.length > 0 ? filteredCategory[0] : []
  }

  setContainerType(containerType) {
    this._selectedContainer = containerType
  }
}

export default EquipmentCategories.getInstance()
