<template lang="pug">
  #content
    admin-header
    router-view
</template>

<script>
import AdminHeader from '@/components/Admin/AdminHeader'

export default {
  name: "Admin",
  components: {AdminHeader}
}
</script>

<style scoped>

</style>
