<template lang="pug">
  .site-header
    .logo
    .divider
    .menu-center
      //.underline
      .item(@click ="push('Orders')" :class="{'active':$route.name === 'Orders'}")
        | Заявки
      .item(@click ="push('Settings')"  :class="{'active':$route.name === 'Settings'}")
        | Настройки
    .menu-left
      .item(@click ="onLogout()")
        | Выход
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import { eventBus } from '@/main';
// import EventBusEvents from '@/common/EventBusEvents';

export default {
  name: 'AdminHeader',
  components: {},
  data () {
    return {
    }
  },

  created() {
  },

  watch: {
  },

  computed: {
    ...mapGetters([
    ]),
  },

  methods: {
    ...mapActions([
      'setUser',
    ]),
    onLogout () {
      this.setUser(null);
      this.$router.push({name: 'Login'})
    },
    push (val) {
      this.$router.push({name: val})
    }
  },
}
</script>
