<template lang="pug">
  .kraus-select.no-select(:class="css")
    .selected-value-wrapper(@click="onSelectedValueClick" )
      .selected-value(v-if="selectedValue") {{selectedValue.title}}
      .selected-value(v-else) {{placeholder}}
      .arrow
    .options-list(:class="{visible: optionsVisible}")
      .option-item(v-for="(item, index) in values", :key="index", @click="onItemClick(item)", v-if="item !== selectedValue") {{item.title}}
</template>
<script>
export default {
  name: 'KrausSelect',
  props: {
    css: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: Object,
      required: false
    },
    values: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedValue: null,
      optionsVisible: false
    }
  },

  mounted() {
    this.selectedValue = this.value
  },

  watch: {
    value(val) {
      this.selectedValue = val
    },

    selectedValue() {
      this.$emit('input', this.selectedValue)
    }
  },

  methods: {
    onItemClick(item) {
      this.selectedValue = item
      this.optionsVisible = false
    },

    onSelectedValueClick() {
      this.optionsVisible = !this.optionsVisible
    }
  }
}
</script>
