import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
  },
  state: {
    user: null,
    jsonData: null,
  },
  mutations: {
    setJson(state, value) {
      state.jsonData = value
    },
    setUser(state, value) {
      state.user = value
    }
  },
  actions: {
    setUser({commit}, data) {
      commit('setUser', data)
    },
  },
  getters: {
    jsonData: state => state.jsonData,
    user: state => state.user,
  }
});
