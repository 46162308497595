import axios from 'axios';

var apiURL = window.location.host !== 'admin.kraus-blocks.ru' ? 'https://api.dev.kraus-blocks.ru/' : 'https://api.kraus-blocks.ru/'

if (window.location.host === 'localhost:8080') {
  apiURL = 'http://127.0.0.1:8000/'
  // apiURL = 'https://api.dev.kraus-blocks.ru/'
  // apiURL = 'https://api.kraus-blocks.ru/'
}

const api = axios.create({
  baseURL: apiURL,
  timeout: 15000
})

export default api
